/* eslint-disable no-console */
import { fetchData } from '@/services/fetchData';
import { setUser } from '@/store';
import { useDispatch } from 'react-redux';

export const useGetMe = () => {
  const dispatch = useDispatch();

  const getMe = async () => {
    try {
      const response = await fetchData({
        url: '/me',
      });

      if (response?.status === 200) {
        dispatch(setUser(response.data));
      } else {
        setTimeout(() => {
          getMe();
        }, 5000);
      }
    } catch (error) {
      await getMe();
    }
  };

  return { getMe };
};
