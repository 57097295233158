import { createAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';
import {
  UserData,
  TourState,
  StoryType,
  PlayerType,
  QuestType,
  TurnType,
} from '@/types';

export const setUser = createAction<UserData>(ACTIONS.SET_USER);

export const setOfferActive = createAction<boolean>(ACTIONS.SET_OFFER_ACTIVE);

export const setSubscribeModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_MODAL_ACTIVE,
);

export const setSubscribeSuccessModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_SUCCESS_MODAL_ACTIVE,
);

export const setSubscribeUpgradeModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_UPGRADE_MODAL_ACTIVE,
);

export const setMysterySolvedModalActive = createAction<boolean>(
  ACTIONS.SET_MYSTERY_SOLVED_MODAL_ACTIVE,
);

export const setOfferTimer = createAction<number>(ACTIONS.SET_OFFER_TIMER);

export const setAppLoading = createAction<boolean>(ACTIONS.SET_APP_LOADING);

export const setOfferUpgradeModalActive = createAction<boolean>(
  ACTIONS.SET_OFFER_UPGRADE_MODAL_ACTIVE,
);

export const setOfferDisclaimerModalActive = createAction<boolean>(
  ACTIONS.SET_OFFER_DISCLAIMER_MODAL_ACTIVE,
);

export const setTourState = createAction<TourState>(ACTIONS.SET_TOUR_STATE);

export const setHelpModalActive = createAction<boolean>(
  ACTIONS.SET_HELP_MODAL_ACTIVE,
);

export const setProgressModalActive = createAction<boolean>(
  ACTIONS.SET_PROGRESS_MODAL_ACTIVE,
);

export const setOutOfTurnsModalActive = createAction<boolean>(
  ACTIONS.SET_OUT_OF_TURNS_MODAL_ACTIVE,
);

export const setStories = createAction<StoryType[]>(ACTIONS.SET_STORIES);

export const setMessageError = createAction<{
  active: boolean;
  message: string;
}>(ACTIONS.SET_MESSAGE_ERROR);

export const setCurrentStory = createAction<StoryType>(
  ACTIONS.SET_CURRENT_STORY,
);

export const setCurrentPlayer = createAction<PlayerType>(
  ACTIONS.SET_CURRENT_PLAYER,
);

export const setCurrentQuest = createAction<QuestType>(
  ACTIONS.SET_CURRENT_QUEST,
);

export const setStoryMessages = createAction<TurnType[]>(
  ACTIONS.SET_STORY_MESSAGES,
);
