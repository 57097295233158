import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { APP_REDUCER_NAME, appReducer } from './app';
import { requestApi } from '@/services/requestApi';

export const store = configureStore({
  reducer: combineReducers({
    [APP_REDUCER_NAME]: appReducer,
    [requestApi.reducerPath]: requestApi.reducer,
  }),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(requestApi.middleware as Middleware),
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from './app';
