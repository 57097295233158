import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { axiosBaseQuery } from './axiosConfig';
import { BACKEND_URL } from '@/constants';

export const requestApi = createApi({
  reducerPath: 'requestApi',
  baseQuery: axiosBaseQuery({
    baseUrl: BACKEND_URL,
  }) as BaseQueryFn<AxiosRequestConfig, unknown, AxiosError>,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: `/login`,
        method: 'POST',
        data: payload,
      }),
    }),
    signUp: builder.mutation({
      query: (payload) => ({
        url: `/register`,
        method: 'POST',
        data: payload,
      }),
    }),
  }),
});

export const { useLoginMutation, useSignUpMutation } = requestApi;
