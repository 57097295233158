export const APP_REDUCER_NAME = 'appContainer';

export enum ACTIONS {
  SET_USER = 'SET_USER',
  SET_OFFER_ACTIVE = 'SET_OFFER_ACTIVE',
  SET_OFFER_TIMER = 'SET_OFFER_TIMER',
  SET_HELP_MODAL_ACTIVE = 'SET_HELP_MODAL_ACTIVE',
  SET_TOUR_STATE = 'SET_TOUR_STATE',
  SET_SUBSCRIBE_MODAL_ACTIVE = 'SET_SUBSCRIBE_MODAL_ACTIVE',
  SET_PROGRESS_MODAL_ACTIVE = 'SET_PROGRESS_MODAL_ACTIVE',
  SET_APP_LOADING = 'SET_APP_LOADING',
  SET_OFFER_UPGRADE_MODAL_ACTIVE = 'SET_OFFER_UPGRADE_MODAL_ACTIVE',
  SET_OFFER_DISCLAIMER_MODAL_ACTIVE = 'SET_OFFER_DISCLAIMER_MODAL_ACTIVE',
  SET_STORIES = 'SET_STORIES',
  SET_CURRENT_STORY = 'SET_CURRENT_STORY',
  SET_CURRENT_PLAYER = 'SET_CURRENT_PLAYER',
  SET_CURRENT_QUEST = 'SET_CURRENT_QUEST',
  SET_STORY_MESSAGES = 'SET_STORY_MESSAGES',
  SET_OUT_OF_TURNS_MODAL_ACTIVE = 'SET_OUT_OF_TURNS_MODAL_ACTIVE',
  SET_MESSAGE_ERROR = 'SET_MESSAGE_ERROR',
  SET_MYSTERY_SOLVED_MODAL_ACTIVE = 'SET_MYSTERY_SOLVED_MODAL_ACTIVE',
  SET_SUBSCRIBE_SUCCESS_MODAL_ACTIVE = 'SET_SUBSCRIBE_SUCCESS_MODAL_ACTIVE',
  SET_SUBSCRIBE_UPGRADE_MODAL_ACTIVE = 'SET_SUBSCRIBE_UPGRADE_MODAL_ACTIVE',
}
