import axios, { AxiosRequestConfig, AxiosError } from 'axios';

interface AxiosBaseQueryArgs {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: any;
  params?: Record<string, any>;
}

interface AxiosBaseQueryConfig {
  baseUrl: string;
}

interface AxiosBaseQueryResult {
  data?: unknown;
  error?: {
    status?: number;
    data?: unknown;
  };
}

export const axiosBaseQuery =
  ({ baseUrl }: AxiosBaseQueryConfig = { baseUrl: '' }) =>
  async ({
    url,
    method,
    data,
    params,
  }: AxiosBaseQueryArgs): Promise<AxiosBaseQueryResult> => {
    const token = localStorage.getItem('botai_access_token');

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
