export const BACKEND_URL =
  'https://ai-chat-game-262065803323.us-central1.run.app';

export const MOBILE_RESOLUTION = 435;
export const TABLET_RESOLUTION = 768;

export const ONBOARDING_PASSED = 'ONBOARDING_PASSED';

export const SUPPORT_URL = 'https://t.me/lostlore_ai';

export const OFFER_TIMER = 'OFFER_TIMER';
