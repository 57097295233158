import { useEffect, useState } from 'react';

export const useTelegram = () => {
  const [telegram, setTelegram] = useState<WebApp>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tgInit = window.Telegram?.WebApp;
      setTelegram(tgInit);
    }
  }, []);

  return telegram;
};
