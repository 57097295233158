import { createReducer } from '@reduxjs/toolkit';

import {
  setUser,
  setOfferTimer,
  setOfferActive,
  setHelpModalActive,
  setTourState,
  setSubscribeModalActive,
  setProgressModalActive,
  setOfferDisclaimerModalActive,
  setOfferUpgradeModalActive,
  setStories,
  setCurrentStory,
  setCurrentPlayer,
  setStoryMessages,
  setCurrentQuest,
  setOutOfTurnsModalActive,
  setMessageError,
  setMysterySolvedModalActive,
  setSubscribeSuccessModalActive,
  setSubscribeUpgradeModalActive,
} from './actions';
import { InitialState } from './types';

const initialState: InitialState = {
  user: null,
  offer: {
    timer: 300,
    active: false,
    disclaimer: false,
    upgrade: false,
  },
  helpModal: false,
  subscribeModal: false,
  subscribeSuccessModal: false,
  subscribeUpgradeModal: false,
  tourState: {
    active: false,
    repeat: false,
  },
  progressModal: false,
  outOfTurnsModal: false,
  mysterySolvedModal: false,
  messageError: {
    active: false,
    message: '',
  },
  appLoading: false,
  stories: [],
  currentStory: null,
  currentQuest: null,
  currentPlayer: null,
  storyMessages: [],
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUser, (state, action) => {
    state.user = action.payload;
  });
  builder.addCase(setOfferActive, (state, action) => {
    state.offer.active = action.payload;
  });
  builder.addCase(setOfferTimer, (state, action) => {
    state.offer.timer = action.payload;
  });
  builder.addCase(setHelpModalActive, (state, action) => {
    state.helpModal = action.payload;
  });
  builder.addCase(setSubscribeModalActive, (state, action) => {
    state.subscribeModal = action.payload;
  });
  builder.addCase(setTourState, (state, action) => {
    state.tourState = action.payload;
  });
  builder.addCase(setProgressModalActive, (state, action) => {
    state.progressModal = action.payload;
  });
  builder.addCase(setOfferDisclaimerModalActive, (state, action) => {
    state.offer.disclaimer = action.payload;
  });
  builder.addCase(setOfferUpgradeModalActive, (state, action) => {
    state.offer.upgrade = action.payload;
  });
  builder.addCase(setStories, (state, action) => {
    state.stories = action.payload;
  });
  builder.addCase(setCurrentStory, (state, action) => {
    state.currentStory = action.payload;
  });
  builder.addCase(setCurrentPlayer, (state, action) => {
    state.currentPlayer = action.payload;
  });
  builder.addCase(setStoryMessages, (state, action) => {
    state.storyMessages = action.payload;
  });
  builder.addCase(setCurrentQuest, (state, action) => {
    state.currentQuest = action.payload;
  });
  builder.addCase(setOutOfTurnsModalActive, (state, action) => {
    state.outOfTurnsModal = action.payload;
  });
  builder.addCase(setMessageError, (state, action) => {
    state.messageError = action.payload;
  });
  builder.addCase(setMysterySolvedModalActive, (state, action) => {
    state.mysterySolvedModal = action.payload;
  });
  builder.addCase(setSubscribeSuccessModalActive, (state, action) => {
    state.subscribeSuccessModal = action.payload;
  });
  builder.addCase(setSubscribeUpgradeModalActive, (state, action) => {
    state.subscribeUpgradeModal = action.payload;
  });
});
