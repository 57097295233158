/* eslint-disable no-console */
'use client';
import styles from './app.module.scss';
import { ReactNode, Suspense, useEffect, useState } from 'react';
import { useLoginMutation, useSignUpMutation } from '@/services/requestApi';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Loader } from '@/components/Loader/Loader';
import { useGetMe } from '@/hooks/useGetMe';
import { useGetStoryLines } from '@/hooks/useGetStorylines';
import { useDispatch, useSelector } from 'react-redux';
import { selectOfferActive, selectOfferTimer, setOfferTimer } from '@/store';
import { OFFER_TIMER } from '@/constants';
import { useCloseOffer } from '@/hooks/useCloseOffer';
import { useTelegram } from '@/hooks/useTelegram';

export const App = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [setLogin] = useLoginMutation();
  const [setSignUp] = useSignUpMutation();
  const { getMe } = useGetMe();
  const { getStorylines } = useGetStoryLines();
  const dispatch = useDispatch();
  const offerActive = useSelector(selectOfferActive);
  const timer = useSelector(selectOfferTimer);
  const { close } = useCloseOffer();
  const telegram = useTelegram();

  useEffect(() => {
    const handleAuthorize = async () => {
      if (telegram) {
        try {
          await setLogin({
            username: telegram.initDataUnsafe?.user?.username,
            // username: 'testUser3@gmail.com',
            // password: '123456',
          })
            .then((response) => {
              if (response?.data?.access_token) {
                localStorage.setItem(
                  'botai_access_token',
                  response?.data?.access_token,
                );
              } else {
                setSignUp({
                  username: telegram.initDataUnsafe?.user?.username,
                  // username: 'testUser3@gmail.com',
                  // password: '123456',
                  referrer_id: 0,
                })
                  .then((res) => {
                    setLogin({
                      username: telegram.initDataUnsafe?.user?.username,
                      // username: 'testUser3@gmail.com',
                      // password: '123456',
                    });

                    return res;
                  })
                  .then((res) => {
                    if (res) {
                      localStorage.setItem(
                        'botai_access_token',
                        res?.data?.access_token,
                      );
                    }
                  });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          console.error(error, 'Failed Authorization');
        }
      }
    };

    handleAuthorize();
  }, [setLogin, telegram, setSignUp]);

  useEffect(() => {
    getStorylines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timer > 0 && offerActive) {
      const timerInterval = setInterval(() => {
        dispatch(setOfferTimer(timer - 1));
        localStorage.setItem(OFFER_TIMER, timer.toString());
      }, 1000);

      return () => clearInterval(timerInterval);
    }

    if (timer === 0) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, offerActive]);

  console.log(telegram?.initDataUnsafe?.user);

  return (
    <TonConnectUIProvider manifestUrl="../../tonconnect-manifest.json">
      <Suspense fallback={<Loader fullScreen />}>
        <div className={styles.app}>
          <main className={styles.content}>
            {loading ? <Loader fullScreen /> : children}
          </main>
        </div>
      </Suspense>
    </TonConnectUIProvider>
  );
};
