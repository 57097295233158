import { ReactNode } from 'react';

export enum FETCH_METHODS {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type ProviderProps = {
  children: ReactNode;
};

export type PlayerType = {
  id: number;
  user_id: number;
  story_id: number;
  quest_id: number;
  quest_active: boolean;
  name: string;
  state: string;
};

export type UserData = {
  username: string;
  turns_left: number;
  coins_left: number;
  id: number;
  players: PlayerType[];
};

export type QuestType = {
  id: number;
  pos: number;
  title: string;
  coins: number;
};

export type NavTabType = {
  label: string;
  icon: string;
  activeIcon: string;
  url: string;
};

export type PlayerQuestType = {
  id: number;
  created_at: number;
  quest: QuestType;
  completed_at: number;
};

export type StoryType = {
  id: number;
  title: string;
  intro: string;
  banner: string;
  book_author: string;
  book_name: string;
  book_year: number;
  source_link: string;
  tags: string[];
  welcome_image: string;
  welcome_title: string;
  welcome_text: string;
  player: PlayerType;
  quest_count: number;
  first_quest: QuestType;
  player_quest: PlayerQuestType;
};

export type TourState = {
  active: boolean;
  repeat: boolean;
};

export type ProgressDataType = {
  clues: {
    img: string;
    items: string[];
  };
  locations: {
    img: string;
    items: string[];
  };
  characters: {
    img: string;
    items: string[];
  };
};

export type TurnType = {
  prompt: string;
  response: {
    completed: 0;
    message: string;
  };
  timestamp: number;
};
